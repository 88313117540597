export const customFunctionService = {
  cloneObject: object => {
    return JSON.parse(JSON.stringify(object));
  },
  downloadCSVFile: function(response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
  },
  downloadExcelFile: function(response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
};

export default customFunctionService;
