import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import AppWrapper from "../AppWrapper.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AppWrapperView",
    component: AppWrapper,
    children: [
      {
        path: "dashboard",
        name: "DashboardView",
        component: () => import("../views/Dashboard.vue"),
        alias: ""
      },
      {
        path: "/animation-islands",
        name: "AnimationIslandView",
        component: () =>
          import("../views/AnimationOnIslands/AnimationOnIslands.vue")
      },
      {
        path: "/:shop_lang/:shop_name",
        name: "ShopWrapperView",
        component: () => import("../ShopWrapper.vue"),
        children: [
          {
            path: "orders",
            name: "OrdersListView",
            component: () => import("../views/Orders/Orders/List.vue")
          },
          {
            path: "orders/notes",
            name: "OrdersNotesListView",
            component: () => import("../views/Orders/Notes/List.vue")
          },
          {
            path: "orders/not-sent",
            name: "OrdersNotSentListView",
            component: () => import("../views/Orders/NotSent/List.vue")
          },
          {
            path: "presentations-gifts",
            name: "PresentationsGiftsView",
            component: () => import("../views/PresentationsGifts/List.vue")
          },
          {
            path: "products",
            name: "ProductsListView",
            component: () => import("../views/Products/Products/List.vue")
          },
          {
            path: "products/product-list",
            name: "ProductExport",
            component: () => import("../views/Products/List/ProductExport.vue")
          },
          {
            path: "products/add-product-to-category",
            name: "AddProductToCategoryView",
            component: () =>
              import(
                "../views/Products/AddProductToCategory/AddProductToCategory.vue"
              )
          },
          {
            path: "products/tags",
            name: "ProductTagsView",
            component: () => import("../views/Products/Tags/List.vue")
          },
          {
            path: "products/:id",
            name: "ProductDetailsView",
            component: () => import("../views/Products/Products/Details.vue")
          },
          {
            path: "statistics/payment-methods",
            name: "PaymentMethodsView",
            component: () => import("../views/Statistics/PaymentsMethods.vue")
          },
          {
            path: "statistics/collections",
            name: "CollectionsSaleStatisticsView",
            component: () =>
              import(
                "../views/Statistics/Sales/Collections/CollectionsSale.vue"
              )
          },
          {
            path: "statistics/selldetails",
            name: "SellDetails",
            component: () =>
              import("../views/Statistics/Sales/SellDetails/SellDetails.vue")
          },
          {
            path: "statistics/bestseller",
            name: "BestsellerSaleStatisticsView",
            component: () =>
              import("../views/Statistics/Sales/Bestseller/BestsellerSale.vue")
          },
          {
            path: "statistics/categories",
            name: "CategoriesSaleStatisticsView",
            component: () =>
              import("../views/Statistics/Sales/Categories/CategoriesSale.vue")
          },
          {
            path: "statistics/products",
            name: "ProductsSaleStatisticsView",
            component: () =>
              import("../views/Statistics/Sales/Products/ProductsSale.vue")
          },
          {
            path: "location",
            name: "Location",
            component: () => import("../views/Location/List.vue")
          },
          {
            path: "location/add",
            name: "AddLocation",
            component: () => import("../views/Location/Details.vue")
          },
          {
            path: "localizations/:id",
            name: "ChangeDetailsInLocation",
            component: () => import("../views/Location/Details.vue")
          },
          {
            path: "statistics/free-deliveries",
            name: "FreeDeliveryView",
            component: () => import("../views/Statistics/FreeDelivery.vue")
          },
          {
            path: "statistics/discount-codes-groups",
            name: "DiscountCodeGroupView",
            component: () => import("../views/Statistics/DiscountCodeGroup.vue")
          },
          {
            path: "statistics/discount-codes",
            name: "DiscountCodeView",
            component: () =>
              import("../views/Statistics/Sales/DiscountCode/DiscountCode.vue")
          },
          {
            path: "statistics/promotions",
            name: "PromotionsSaleView",
            component: () =>
              import("../views/Statistics/Sales/Promotions/PromotionsSale.vue")
          },
          {
            path: "newsletter",
            name: "NewsletterView",
            component: () => import("../views/Newsletter/Newsletter.vue")
          },
          {
            path: "statistics/registrations",
            name: "RegistrationView",
            component: () => import("../views/Statistics/Registration.vue")
          },
          {
            path: "short-redirects",
            name: "ShortRedirectView",
            component: () => import("../views/ShortRedirectUrl/List.vue")
          },
          {
            path: "short-redirects/add",
            name: "AddShortRedirectDetailsView",
            component: () => import("../views/ShortRedirectUrl/Details.vue")
          },
          {
            path: "short-redirects/:id",
            name: "ShortRedirectDetailsView",
            component: () => import("../views/ShortRedirectUrl/Details.vue")
          },
          {
            path: "qr-code/generate",
            name: "QrCodeGenerateView",
            component: () => import("../views/QrCode/Generate.vue")
          },
          {
            path: "qr-code/upload-files",
            name: "QrCodeUploadFiles",
            component: () => import("../views/QrCode/UploadFiles.vue")
          },
          {
            path: "awards-and-certificates",
            name: "AwardsAndCertificatesView",
            component: () => import("../views/AwardsAndCertificates/List.vue")
          },
          // {
          //   path: "awards-and-certificates/list/:id",
          //   name: "AwardsAndCertificatesDetails",
          //   component: () =>
          //     import("../views/AwardsAndCertificates/Details.vue")
          // },
          {
            path: "awards-and-certificates/add",
            name: "AwardsAndCertificatesAddView",
            component: () =>
              import("../views/AwardsAndCertificates/AwardsAndCertificates.vue")
          },
          {
            path: "courses/applications-list",
            name: "CoursesApplicationsList",
            component: () => import("../views/Courses/ApplicationsList.vue")
          },
          {
            path: "courses/training-dates",
            name: "CoursesDatesList",
            component: () => import("../views/Courses/TrainingDatesList.vue")
          },
          {
            path: "courses/training-dates/add",
            name: "CoursesDatesAdd",
            component: () => import("../views/Courses/TrainingDatesDetails.vue")
          },
          {
            path: "courses/training-dates/:id",
            name: "CoursesDatesDetails",
            component: () => import("../views/Courses/TrainingDatesDetails.vue")
          },
          {
            path: "courses/cities",
            name: "CoursesCitiesList",
            component: () => import("../views/Courses/TrainingCitiesList.vue")
          },
          {
            path: "courses/cities/add",
            name: "CoursesCitiesAdd",
            component: () =>
              import("../views/Courses/TrainingCitiesDetails.vue")
          },
          {
            path: "courses/cities/:id",
            name: "CoursesCitiesDetails",
            component: () =>
              import("../views/Courses/TrainingCitiesDetails.vue")
          },
          {
            path: "courses/training-category",
            name: "CoursesCategoriesList",
            component: () =>
              import("../views/Courses/TrainingCategoriesList.vue")
          },
          {
            path: "courses/training-category/add",
            name: "CoursesCategoriesAdd",
            component: () =>
              import("../views/Courses/TrainingCategoriesDetails.vue")
          },
          {
            path: "courses/training-category/:id",
            name: "CoursesCategoriesDetails",
            component: () =>
              import("../views/Courses/TrainingCategoriesDetails.vue")
          },
          {
            path: "courses/training-trainings",
            name: "CoursesTrainingsList",
            component: () =>
              import("../views/Courses/TrainingTrainingsList.vue")
          },
          {
            path: "courses/training-trainings/add",
            name: "CoursesTrainingsAdd",
            component: () =>
              import("../views/Courses/TrainingTrainingsDetails.vue")
          },
          {
            path: "courses/training-trainings/:id",
            name: "CoursesTrainingsDetails",
            component: () =>
              import("../views/Courses/TrainingTrainingsDetails.vue")
          },
          {
            path: "courses/seo",
            name: "CoursesSeoList",
            component: () => import("../views/Courses/TrainingSeoList.vue")
          },
          {
            path: "courses/stylists",
            name: "CoursesStylistsList",
            component: () => import("../views/Courses/TrainingStylistsList.vue")
          },
          {
            path: "courses/stylists/add",
            name: "CoursesStylistsAdd",
            component: () =>
              import("../views/Courses/TrainingStylistsDetails.vue")
          },
          {
            path: "courses/stylists/:id",
            name: "CoursesStylistsDetails",
            component: () =>
              import("../views/Courses/TrainingStylistsDetails.vue")
          },
          {
            path: "courses/regulations",
            name: "TrainingRegulationsDetails",
            component: () =>
              import("../views/Courses/TrainingRegulationsDetails.vue")
          },
          {
            path: "subpages-lp-edu",
            name: "SubpagesList",
            component: () => import("../views/Subpages/List.vue")
          }
          // {
          //   path: "subpages-lp-edu/edit/be-inspired/:id",
          //   name: "SubpagesListEdit",
          //   component: () => import("../views/Subpages/EditBeInspired.vue")
          // },
          // {
          //   path: "subpages-lp-edu/edit/know-more/:id",
          //   name: "SubpagesListEdit",
          //   component: () => import("../views/Subpages/EditKnowMore.vue")
          // },
          // {
          //   path: "subpages-lp-edu/edit/frequent-questions/:id",
          //   name: "SubpagesListEdit",
          //   component: () =>
          //     import("../views/Subpages/EditFrequentQuestions.vue")
          // },
          // {
          //   path: "subpages-lp-edu/edit/compare-products/:id",
          //   name: "SubpagesListEdit",
          //   component: () => import("../views/Subpages/EditCompareProducts.vue")
          // },
          // {
          //   path: "subpages-lp-edu/edit/video-tutorials/:id",
          //   name: "SubpagesListEdit",
          //   component: () => import("../views/Subpages/EditVideoTutorials.vue")
          // },
          // {
          //   path: "subpages-lp-edu/edit/how-to-start/:id",
          //   name: "SubpagesListEdit",
          //   component: () => import("../views/Subpages/EditHowToStart.vue")
          // }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  linkExactActiveClass: "active",
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
