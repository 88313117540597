import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
// import { v4 as uuidv4 } from "uuid";
import msal from "vue-msal";

import moment from "moment";
import VeeValidate from "vee-validate";
import toastr from "./toastr";
import VueMask from "v-mask";
import datePicker from "vue-bootstrap-datetimepicker";
import vSelect from "vue-select";
import vueDropzone from "vue2-dropzone";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import "bootstrap-vue/dist/bootstrap-vue.css";

import "./assets/css/animate.css";
import "./assets/css/font-awesome.css";
import "./assets/css/toastr.min.css";
import "./assets/css/bootstrap.css";
import "./assets/css/data-tables.css";
import "./assets/css/bootstrap-datetimepicker.css";
import "./assets/css/style.css";
import "./assets/css/custom.scss";
import "vue-select/dist/vue-select.css";

import i18n from "./i18n";
import customFunction from "./services/customFunction.service";

import ApiService from "./services/api.service";
ApiService.init();

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.use(VueCookies);
Vue.use(VeeValidate);
Vue.use(VueMask);
Vue.use(datePicker);

import CustomValidations from "./services/customValidator.service";
CustomValidations.init();

Vue.component("vueDropzone", vueDropzone);
Vue.component("v-select", vSelect);

Vue.prototype.$moment = moment;
Vue.prototype.$toastr = toastr;
Vue.prototype.$http = ApiService;
Vue.prototype.$func = customFunction;

Vue.use(msal, {
  auth: {
    clientId: "2ee8189c-a500-4a0a-9cc8-67cbefeb32cf",
    authority:
      "https://login.microsoftonline.com/7fe5fcb9-8b91-49f5-95a8-6014f804d2e6/",
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URL,

    onToken: (ctx, error) => {
      if (!error) {
        ApiService.setHeader(ctx.data.accessToken);
      }
    }
  },
  graph: {
    callAfterInit: true,
    endpoints: {
      profile:
        "/me?$select=department,id,mail,userPrincipalName,displayName,jobTitle",
      // photo: { url: '/me/photo/$value', responseType: 'blob', force: true }
      messages: "/me/messages"
    }
  },
  framework: {
    globalMixin: true
  }
});

Vue.filter("currency", value => {
  const val = (value / 1).toFixed(2).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
});

Vue.filter("slug", str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "")
);

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = event => {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});

Vue.directive("lazyload", {
  inserted: el => {
    function loadImage() {
      ApiService.customRequest({
        url: `${el.dataset.url}/${el.dataset.id}`,
        method: "GET",
        responseType: "blob"
      }).then(resp => {
        if (resp.headers["content-type"] !== "application/pdf") {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          el.addEventListener("load", () => {
            setTimeout(() => el.classList.add("loaded"), 100);
          });
          el.addEventListener("error", err => console.error(err));
          el.src = url;
        } else {
          el.src = "";
        }
      });
    }

    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: "0"
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }
    if (window.IntersectionObserver) {
      createObserver();
    } else {
      loadImage();
    }
  }
});

Vue.mixin({
  data() {
    return {
      optionsDate: {
        format: "YYYY-MM-DD",
        useCurrent: false
      },
      optionsWithTime: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false
      },
      optionsMonth: {
        format: "YYYY-MM",
        useCurrent: false
      }
    };
  }
});

Sentry.init({
  Vue,
  dsn:
    "https://dc69c9cf74d241bdaf435debe7dc74c4@o441536.ingest.sentry.io/6658150",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["dev.panel.cosmogroup.pl", /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn();
    }
    console.log("beforeRouteEnter", to, from);
    next();
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate", to, from);
    next();
  }
}).$mount("#app");
