<template>
  <div class="row border-bottom">
    <nav
      class="navbar navbar-static-top"
      role="navigation"
      style="margin-bottom: 0"
    >
      <div class="navbar-header">
        <a
          class="navbar-minimalize minimalize-styl-2 btn btn-primary "
          href="#"
          @click.prevent="minimalizeNavbar"
        >
          <i class="fa fa-bars"></i>
        </a>
      </div>
      <ul class="nav navbar-top-links navbar-right">
        <li class="mr-2" style="color:red;">
          <i class="fas fa-language"></i>
        </li>
        <li class="lang">
          <span @click.prevent="langChange('pl')">
            PL
          </span>
        </li>
        <li>
          <a href="#" @click.prevent="signOut">
            <i class="fa fa-sign-out"></i> Log out
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { msalMixin } from "vue-msal";

export default {
  name: "TopBar",
  mixins: [msalMixin],
  data() {
    return {
      minimalize: false,
      alerts_show: false,
      messages_show: false
    };
  },
  methods: {
    signOut() {
      this.deleteCookies();
      localStorage.clear();
      this.$msal.signOut();
    },
    deleteCookies() {
      const allCookies = document.cookie.split(";");
      for (var i = 0; i < allCookies.length; i++)
        document.cookie = `${allCookies[i]}
        =;expires=${new Date(0).toUTCString()}`;
    },
    minimalizeNavbar() {
      this.minimalize
        ? document.querySelector("body").classList.remove("mini-navbar")
        : document.querySelector("body").classList.add("mini-navbar");
      this.minimalize = !this.minimalize;
    },
    showDropdownAlert() {
      this.alerts_show = !this.alerts_show;
      this.messages_show = false;
    },
    showDropdownMessages() {
      this.messages_show = !this.messages_show;
      this.alerts_show = false;
    },
    langChange(lang) {
      this.$i18n.locale = lang;
      document.cookie = `lang=${lang}`;
      this.$forceUpdate();
    }
  }
};
</script>
