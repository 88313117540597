<template>
  <div class="col-lg-12">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/dashboard">
          Home
        </router-link>
      </li>
      <li class="breadcrumb-item" v-for="(route, i) in routes" :key="i">
        <strong v-if="i == routes.length - 1">
          <span v-if="!isNaN(route)">
            Edycja
          </span>
          <span v-else>
            {{ route.charAt(0).toUpperCase() + route.slice(1) }}
          </span>
        </strong>
        <router-link v-else :to="'/' + route">
          {{ route.charAt(0).toUpperCase() + route.slice(1) }}
        </router-link>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  computed: {
    routes() {
      if (this.$route.path === "/dashboard") {
        return [];
      } else {
        const arr = this.$route.path.split("/");
        return arr.filter(route => route !== "");
      }
    }
  }
};
</script>
