var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar-default navbar-static-side",attrs:{"role":"navigation"}},[_c('div',{staticClass:"sidebar-collapse"},[_c('ul',{staticClass:"nav metismenu",attrs:{"id":"side-menu"}},[(_vm.user)?_c('li',{staticClass:"nav-header"},[(_vm.user.profile)?_c('div',{staticClass:"dropdown profile-element"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('span',{staticClass:"block m-t-xs font-bold"},[_vm._v(" "+_vm._s(_vm.user.profile.displayName)+" ")]),_c('span',{staticClass:"text-muted text-xs block"},[_vm._v(" "+_vm._s(_vm.user.profile.jobTitle)+" ")]),_c('span',{staticClass:"text-muted text-xs block"},[_vm._v(" "+_vm._s(_vm.user.profile.department)+" ")])])]):_vm._e(),_c('div',{staticClass:"logo-element p-0"},[_c('svg',{staticStyle:{"fill":"#fff"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"100 150 242 230"}},[_c('path',{attrs:{"shape-rendering":"geometricPrecision","d":"M171.14 324.54h13.6s15.03-26.34 31.23-48.16c10.1-13.6 18.14-23.57 28-31.96 7.2-6.12 11.16-9.67 20.83-10.84 7.15-.87 5.48 6.83 5.39 7.68-.1.86-2.24 8.06-6.58 18L247.18 297l-18.42 42.5 12.41 5.1 32.37-78.51 5.53-13.58s7.48-15.9-1.02-19.99c-7.71-3.7-18.4-.98-23.1 1.25 0 0-9.71 4.81-19.47 14.5s-24.84 29.38-33.13 41.89c-8.29 12.5-13.33 20.68-13.33 20.68s12.9-26.7 18.69-40.14c5.78-13.44 8.64-26.43 8.42-29.52-.22-3.1-.28-8.3-6.98-9.51-4.96-.9-13.8 1.25-19.2 4.67-5.42 3.41-15.24 11.55-23.3 21.65-5.18 6.5-4.18 5.53-4.18 5.53l7.48 7.82s16.8-28.78 28.14-35.5c2.96-1.74 6.37-2.96 8.57-1.33 2.19 1.63-.48 7.85-.67 8.44-.2.6-1.18 4.8-9.74 24.24l-18.5 42.03-6.61"}})])])]):_vm._e(),_vm._l((_vm.navData),function(nav,index){return [(nav.path)?_c('router-link',{key:("router-" + index),attrs:{"to":("" + (nav.path)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('a',[_c('span',{domProps:{"innerHTML":_vm._s(nav.icon)}}),_c('span',{staticClass:"nav-label"},[_vm._v(_vm._s(nav.name))])])])]}}],null,true)}):_vm._e(),(nav.sub)?_c('li',{key:("li-" + index)},[_c('a',{staticClass:"nav-collapse-controller",attrs:{"data-toggle":"collapse","href":("#collapse" + index),"aria-expanded":"false"}},[_c('span',{domProps:{"innerHTML":_vm._s(nav.icon)}}),_c('span',{staticClass:"nav-label"},[_vm._v(_vm._s(nav.name))]),_c('span',{staticClass:"fa arrow"})]),_c('ul',{staticClass:"nav nav-second-level collapse",attrs:{"id":("collapse" + index)}},[_vm._l((nav.sub),function(sub,sub_index){return [(sub.path)?_c('router-link',{key:("router-" + index + "-" + sub_index),attrs:{"to":("" + (sub.path)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('a',[_vm._v(" "+_vm._s(sub.name)+" ")])])]}}],null,true)}):_vm._e(),_c('li',{key:("li-" + index + "-" + sub_index)},[(sub.sub)?_c('a',{staticClass:"nav-collapse-controller",attrs:{"data-toggle":"collapse","href":("#collapse" + index + "-" + sub_index),"aria-expanded":"false"}},[_c('span',{staticClass:"nav-label"},[_vm._v(_vm._s(sub.name))]),_c('span',{staticClass:"fa arrow"})]):_vm._e(),(sub.sub)?_c('ul',{staticClass:"nav nav-third-level collapse",attrs:{"id":("collapse" + index + "-" + sub_index)}},[_vm._l((sub.sub),function(sub_sub,sub_sub_index){return [(sub_sub.path)?_c('router-link',{key:("router-" + index + "-" + sub_index + "-" + sub_sub_index),attrs:{"to":("" + (sub_sub.path)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('a',[_vm._v(" "+_vm._s(sub_sub.name)+" ")])])]}}],null,true)}):_vm._e(),_c('li',{key:("li-" + index + "-" + sub_index + "-" + sub_sub_index)},[(sub_sub.sub)?_c('a',{staticClass:"nav-collapse-controller",attrs:{"data-toggle":"collapse","href":("#collapse" + index + "-" + sub_index + "-" + sub_sub_index),"aria-expanded":"false"}},[_vm._v(" "+_vm._s(sub_sub.name)+" "),_c('span',{staticClass:"fa arrow"})]):_vm._e(),(sub_sub.sub)?_c('ul',{staticClass:"nav nav-fourth-level collapse",attrs:{"id":("collapse" + index + "-" + sub_index + "-" + sub_sub_index)}},_vm._l((sub_sub.sub),function(sub_sub_sub,sub_sub_sub_index){return _c('router-link',{key:("router-" + index + "-" + sub_index + "-" + sub_sub_index + "-" + sub_sub_sub_index),attrs:{"to":("" + (sub_sub_sub.path)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('a',[_vm._v(" "+_vm._s(sub_sub_sub.name)+" ")])])]}}],null,true)})}),1):_vm._e()])]})],2):_vm._e()])]})],2)]):_vm._e()]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }