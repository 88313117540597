const state = {
  modalVisible: false,
  modalComponent: null,
  modalData: []
};

const getters = {
  modalVisible: state => state.modalVisible,
  modalComponent: state => state.modalComponent,
  modalData: state => state.modalData
};

const actions = {
  showModal({ commit }, { component, data }) {
    commit("restartData");
    commit("showModal", { component });
    commit("setData", { data });

    const body = document.body;
    body.style.overflow = "hidden";
  },
  hideModal({ commit }) {
    commit("hideModal");
    const body = document.body;
    body.style.overflow = "auto";
  },
  setData({ commit }, { data }) {
    commit("setData", { data });
  }
};

const mutations = {
  showModal(state, { component }) {
    state.modalComponent = component;
    state.modalVisible = true;
  },
  hideModal(state) {
    state.modalVisible = false;
    state.modalComponent = null;
  },
  setData(state, { data }) {
    let componentName =
      state.modalComponent.name !== undefined
        ? state.modalComponent.name
        : "defaultModel";
    let modalData = {
      component: componentName,
      data: data !== undefined ? data : {}
    };
    state.modalData = modalData;
  },
  restartData(state) {
    state.modalComponent = null;
    state.modalData = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
