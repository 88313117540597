<template>
  <div id="wrapper">
    <navbar />
    <div id="page-wrapper" class="gray-bg dashbard-1" style="min-height: 100vh">
      <top-bar />
      <div class="row wrapper border-bottom white-bg page-heading pt-3">
        <breadcrumb />
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper wrapper-content animated fadeInRight">
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="float-right">
          Wersja <strong>[AIV]{version}[/AIV]</strong> z dnia
          <strong>[AIV]{date}[/AIV]</strong>.
        </div>
        <div>
          <strong>Copyright</strong> Cosmo Group Sp. z o.o. Sp. K. -
          {{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/base/Navbar.vue";
import TopBar from "@/components/base/TopBar.vue";
import Breadcrumb from "@/components/base/Breadcrumb.vue";

export default {
  name: "AppWrapper",
  components: {
    Navbar,
    TopBar,
    Breadcrumb
  }
};
</script>
