<template>
  <div class="modalWindow" v-show="modalVisible">
    <div class="modal-backdrop"></div>
    <div
      @click="onClickOutside"
      data-key="modal-dialog"
      class="modal inmodal"
      role="dialog"
    >
      <div class="modal-dialog modal-lg">
        <component v-bind:is="modalComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Vue from "vue";

export default {
  name: "ModalWindow",
  data() {
    return {};
  },
  created() {
    const escapeHandler = e => {
      if (e.key === `Escape` && this.modalVisible) {
        this.hideModal();
      }
    };
    document.addEventListener(`keydown`, escapeHandler);
    this.$once(`hook:destroyed`, () => {
      document.removeEventListener(`keydown`, escapeHandler);
    });
  },
  methods: {
    onClickOutside(e) {
      if (e.path[0].dataset.key == "modal-dialog") {
        this.hideModal();
      }
    },
    ...mapActions("modalWindow", [`hideModal`])
  },
  computed: {
    ...mapGetters("modalWindow", ["modalVisible", "modalComponent"])
  }
};
</script>

<style scoped>
.inmodal {
  display: block;
}

.modal-backdrop {
  display: block;
  height: 100vh;
  widows: 100vw;
}
</style>
