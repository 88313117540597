import axios from "axios";
import toastr from "../toastr";

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  },
  getBaseUrl() {
    return axios.defaults.baseURL;
  },
  setHeader(header) {
    axios.defaults.headers.common.Authorization = `Bearer ${header}`;
  },
  getToken() {
    return axios.defaults.headers.common.Authorization;
  },
  removeHeader() {
    axios.defaults.headers.common = {};
  },

  async get(url, data = {}) {
    try {
      return await axios.get(url, data);
    } catch (err) {
      toastr.error(err);
      return Promise.reject(err);
    }
  },

  async post(url, data) {
    try {
      return await axios.post(url, data);
    } catch (err) {
      toastr.error(err);
      return Promise.reject(err);
    }
  },

  async put(url, data) {
    try {
      return await axios.put(url, data);
    } catch (err) {
      toastr.error(err);
      return Promise.reject(err);
    }
  },

  async delete(url, data) {
    try {
      return await axios.delete(url, data);
    } catch (err) {
      toastr.error(err);
      return Promise.reject(err);
    }
  },

  async customRequest(data) {
    return await axios(data);
  }
};

export default ApiService;
