<template>
  <div>
    <modal-window> </modal-window>
    <router-view v-if="msal.isAuthenticated && msal.accessToken" />
    <div class="gray-bg" v-else style="weight: 100%; min-height: 100vh">
      <div class="spiner-app">
        <div class="sk-spinner sk-spinner-chasing-dots">
          <div class="sk-dot1"></div>
          <div class="sk-dot2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { msalMixin } from "vue-msal";
import ModalWindow from "./views/ModalWindow.vue";

export default {
  name: "App",
  mixins: [msalMixin],
  components: {
    ModalWindow,
  },
  created() {
    this.$i18n.locale = document.cookie.lang || "pl";
    this.$http.setHeader(this.msal.accessToken);
  },
  watch: {
    "msal.accessToken": function() {
      if (this.msal.accessToken) {
        this.$http.setHeader(this.msal.accessToken);
      }
    },
  },
};
</script>

<style scoped>
.spiner-app {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
