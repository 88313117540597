<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li class="nav-header" v-if="user">
          <div class="dropdown profile-element" v-if="user.profile">
            <a
              data-toggle="dropdown"
              class="dropdown-toggle"
              href="#"
              @click.prevent
            >
              <span class="block m-t-xs font-bold">
                {{ user.profile.displayName }}
              </span>
              <span class="text-muted text-xs block">
                {{ user.profile.jobTitle }}
              </span>
              <span class="text-muted text-xs block">
                {{ user.profile.department }}
              </span>
            </a>
          </div>
          <div class="logo-element p-0">
            <svg
              style="fill: #fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="100 150 242 230"
            >
              <path
                shape-rendering="geometricPrecision"
                d="M171.14 324.54h13.6s15.03-26.34 31.23-48.16c10.1-13.6 18.14-23.57 28-31.96 7.2-6.12 11.16-9.67 20.83-10.84 7.15-.87 5.48 6.83 5.39 7.68-.1.86-2.24 8.06-6.58 18L247.18 297l-18.42 42.5 12.41 5.1 32.37-78.51 5.53-13.58s7.48-15.9-1.02-19.99c-7.71-3.7-18.4-.98-23.1 1.25 0 0-9.71 4.81-19.47 14.5s-24.84 29.38-33.13 41.89c-8.29 12.5-13.33 20.68-13.33 20.68s12.9-26.7 18.69-40.14c5.78-13.44 8.64-26.43 8.42-29.52-.22-3.1-.28-8.3-6.98-9.51-4.96-.9-13.8 1.25-19.2 4.67-5.42 3.41-15.24 11.55-23.3 21.65-5.18 6.5-4.18 5.53-4.18 5.53l7.48 7.82s16.8-28.78 28.14-35.5c2.96-1.74 6.37-2.96 8.57-1.33 2.19 1.63-.48 7.85-.67 8.44-.2.6-1.18 4.8-9.74 24.24l-18.5 42.03-6.61"
              ></path>
            </svg>
          </div>
        </li>

        <template v-for="(nav, index) in navData">
          <router-link
            v-if="nav.path"
            :to="`${nav.path}`"
            custom
            v-slot="{ navigate }"
            :key="`router-${index}`"
          >
            <li @click="navigate" @keypress.enter="navigate" role="link">
              <a>
                <span v-html="nav.icon"></span>
                <span class="nav-label">{{ nav.name }}</span>
              </a>
            </li>
          </router-link>
          <li v-if="nav.sub" :key="`li-${index}`">
            <a
              class="nav-collapse-controller"
              data-toggle="collapse"
              :href="`#collapse${index}`"
              aria-expanded="false"
            >
              <span v-html="nav.icon"></span>
              <span class="nav-label">{{ nav.name }}</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level collapse" :id="`collapse${index}`">
              <template v-for="(sub, sub_index) in nav.sub">
                <router-link
                  v-if="sub.path"
                  :to="`${sub.path}`"
                  custom
                  v-slot="{ navigate }"
                  :key="`router-${index}-${sub_index}`"
                >
                  <li @click="navigate" @keypress.enter="navigate" role="link">
                    <a>
                      {{ sub.name }}
                    </a>
                  </li>
                </router-link>
                <li :key="`li-${index}-${sub_index}`">
                  <a
                    v-if="sub.sub"
                    class="nav-collapse-controller"
                    data-toggle="collapse"
                    :href="`#collapse${index}-${sub_index}`"
                    aria-expanded="false"
                  >
                    <span class="nav-label">{{ sub.name }}</span>
                    <span class="fa arrow"></span>
                  </a>
                  <ul
                    v-if="sub.sub"
                    class="nav nav-third-level collapse"
                    :id="`collapse${index}-${sub_index}`"
                  >
                    <template v-for="(sub_sub, sub_sub_index) in sub.sub">
                      <router-link
                        v-if="sub_sub.path"
                        :to="`${sub_sub.path}`"
                        custom
                        v-slot="{ navigate }"
                        :key="`router-${index}-${sub_index}-${sub_sub_index}`"
                      >
                        <li
                          @click="navigate"
                          @keypress.enter="navigate"
                          role="link"
                        >
                          <a>
                            {{ sub_sub.name }}
                          </a>
                        </li>
                      </router-link>
                      <li :key="`li-${index}-${sub_index}-${sub_sub_index}`">
                        <a
                          v-if="sub_sub.sub"
                          class="nav-collapse-controller"
                          data-toggle="collapse"
                          :href="
                            `#collapse${index}-${sub_index}-${sub_sub_index}`
                          "
                          aria-expanded="false"
                        >
                          {{ sub_sub.name }}
                          <span class="fa arrow"></span>
                        </a>
                        <ul
                          v-if="sub_sub.sub"
                          class="nav nav-fourth-level collapse"
                          :id="`collapse${index}-${sub_index}-${sub_sub_index}`"
                        >
                          <router-link
                            v-for="(sub_sub_sub,
                            sub_sub_sub_index) in sub_sub.sub"
                            :key="
                              `router-${index}-${sub_index}-${sub_sub_index}-${sub_sub_sub_index}`
                            "
                            :to="`${sub_sub_sub.path}`"
                            custom
                            v-slot="{ navigate }"
                          >
                            <li
                              @click="navigate"
                              @keypress.enter="navigate"
                              role="link"
                            >
                              <a>
                                {{ sub_sub_sub.name }}
                              </a>
                            </li>
                          </router-link>
                        </ul>
                      </li>
                    </template>
                  </ul>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
import { msalMixin } from "vue-msal";
// import NavbarService from "../../services/navbar.service"

export default {
  name: "Navbar",
  mixins: [msalMixin],
  data() {
    return {
      navData: [
        {
          name: this.$t("nav.dashboard"),
          icon: '<i class="fa fa-th-large"></i>',
          path: "/"
        },
        {
          name: "NEONAIL PL",
          icon: '<i class="fa fa-shopping-cart"></i>',
          sub: [
            // {
            //   name: this.$t("nav.orders"),
            //   sub: [
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/pl/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders-notes"),
            //       path: "/pl/neonail/orders/notes"
            //     },
            //     {
            //       name: this.$t("nav.orders-not-sent"),
            //       path: "/pl/neonail/orders/not-sent"
            //     }
            //   ]
            // },
            {
              name: this.$t("nav.products"),
              sub: [
                {
                  name: this.$t("nav.products"),
                  path: "/pl/neonail/products"
                },
                {
                  name: this.$t("nav.product-list"),
                  path: "/pl/neonail/products/product-list"
                },
                {
                  name: this.$t("nav.add-products-to-the-category"),
                  path: "/pl/neonail/products/add-product-to-category"
                },
                {
                  name: this.$t("nav.set-configuration"),
                  path: "/pl/neonail/products/add-product-to-category"
                }
                // {
                //   name: this.$t("nav.tags"),
                //   path: "/pl/neonail/products/tags"
                // }
              ]
            },
            // {
            //   name: this.$t("nav.categories"),
            //   sub: [
            //     {
            //       name: this.$t("nav.categories"),
            //       path: "/pl/neonail/orders"
            //       // },
            //       // {
            //       //   name: this.$t("nav.orders"),
            //       //   path: "/pl/neonail/add-products-to-the-category"
            //     }
            //   ]
            // },
            // {
            //   name: this.$t("nav.newsletter"),
            //   icon: '<i class="fa fa-th-large"></i>',
            //   path: "/pl/neonail/newsletter"
            // },
            {
              name: this.$t("nav.awards-and-certificates"),
              icon: '<i class="fa fa-th-large"></i>',
              path: "/pl/neonail/awards-and-certificates"
            },
            // {
            //   name: this.$t("nav.discount-codes"),
            //   path: "/pl/neonail/discount-codes"
            // },
            // {
            //   name: this.$t("nav.customers"),
            //   path: "/pl/neonail/customers"
            // },
            {
              name: this.$t("nav.faq"),
              path: "/pl/neonail/faq"
            },
            {
              name: this.$t("nav.statistics"),
              sub: [
                // {
                //   name: this.$t("nav.sell"),
                //   sub: [
                //     {
                //       name: this.$t("nav.bestseller"),
                //       path: "/pl/neonail/statistics/bestseller"
                //     },
                //     {
                //       name: this.$t("nav.free-deliveries"),
                //       path: "/pl/neonail/statistics/free-deliveries"
                //     },
                //     {
                //       name: this.$t("nav.sales-products"),
                //       path: "/pl/neonail/statistics/products"
                //     },
                //     {
                //       name: this.$t("nav.sales-categories"),
                //       path: "/pl/neonail/statistics/categories"
                //     },
                //     {
                //       name: this.$t("nav.collections"),
                //       path: "/pl/neonail/statistics/collections"
                //     },
                //     {
                //       name: this.$t("nav.promotions"),
                //       path: "/pl/neonail/statistics/promotions"
                //     },
                //     {
                //       name: this.$t("nav.discount-codes"),
                //       path: "/pl/neonail/statistics/discount-codes"
                //     },
                //     {
                //       name: this.$t("nav.discount-code-groups-statistics"),
                //       path: "/pl/neonail/statistics/discount-codes-groups"
                //     },
                //     {
                //       name: this.$t("nav.orders-notice"),
                //       path: "/pl/neonail/statistics/selldetails"
                //     }
                //   ]
                // },
                // {
                //   name: this.$t("nav.payment-methods"),
                //   path: "/pl/neonail/statistics/payment-methods"
                // },
                // {
                //   name: this.$t("nav.delivery-methods"),
                //   path: "/pl/neonail/statistics/delivery-methods"
                // },
                {
                  name: this.$t("nav.registrations"),
                  path: "/pl/neonail/statistics/registrations"
                }
              ]
            },
            {
              name: this.$t("nav.location"),
              path: "/pl/neonail/location"
            }
          ]
        },
        {
          name: "NEONAIL DE",
          icon: '<i class="fa fa-shopping-cart"></i>',
          sub: [
            // {
            //   name: this.$t("nav.orders"),
            //   sub: [
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/de/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders-notes"),
            //       path: "/de/neonail/orders/notes"
            //     },
            //     {
            //       name: this.$t("nav.orders-not-sent"),
            //       path: "/de/neonail/orders/not-sent"
            //     }
            //   ]
            // },
            {
              name: this.$t("nav.products"),
              sub: [
                {
                  name: this.$t("nav.products"),
                  path: "/de/neonail/products"
                },
                {
                  name: this.$t("nav.product-list"),
                  path: "/de/neonail/products/product-list"
                },
                {
                  name: this.$t("nav.add-products-to-the-category"),
                  path: "/de/neonail/products/add-product-to-category"
                },
                {
                  name: this.$t("nav.set-configuration"),
                  path: "/de/neonail/products/add-product-to-category"
                }
                // {
                //   name: this.$t("nav.tags"),
                //   path: "/de/neonail/products/tags"
                // }
              ]
            },
            // {
            //   name: this.$t("nav.categories"),
            //   sub: [
            //     {
            //       name: this.$t("nav.categories"),
            //       path: "/de/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/de/neonail/add-products-to-the-category"
            //     }
            //   ]
            // },
            // {
            //   name: this.$t("nav.newsletter"),
            //   icon: '<i class="fa fa-th-large"></i>',
            //   path: "/de/neonail/newsletter"
            // },
            {
              name: this.$t("nav.awards-and-certificates"),
              icon: '<i class="fa fa-th-large"></i>',
              path: "/de/neonail/awards-and-certificates"
            },
            {
              name: this.$t("nav.subpages-lp-edu"),
              icon: '<i class="fa fa-file-code-o"></i>',
              path: "/de/neonail/subpages-lp-edu"
            },
            // {
            //   name: this.$t("nav.discount-codes"),
            //   path: "/de/neonail/discount-codes"
            // },
            // {
            //   name: this.$t("nav.customers"),
            //   path: "/de/neonail/customers"
            // },
            {
              name: this.$t("nav.faq"),
              path: "/de/neonail/faq"
            },
            {
              name: this.$t("nav.statistics"),
              sub: [
                // {
                //   name: this.$t("nav.sell"),
                //   sub: [
                //     {
                //       name: this.$t("nav.bestseller"),
                //       path: "/de/neonail/statistics/bestseller"
                //     },
                //     {
                //       name: this.$t("nav.free-deliveries"),
                //       path: "/de/neonail/statistics/free-deliveries"
                //     },
                //     {
                //       name: this.$t("nav.sales-products"),
                //       path: "/de/neonail/statistics/products"
                //     },
                //     {
                //       name: this.$t("nav.sales-categories"),
                //       path: "/de/neonail/statistics/categories"
                //     },
                //     {
                //       name: this.$t("nav.promotions"),
                //       path: "/de/neonail/statistics/promotions"
                //     },
                //     {
                //       name: this.$t("nav.collections"),
                //       path: "/de/neonail/statistics/collections"
                //     },
                //     {
                //       name: this.$t("nav.discount-codes"),
                //       path: "/de/neonail/statistics/discount-codes"
                //     },
                //     {
                //       name: this.$t("nav.discount-code-groups-statistics"),
                //       path: "/de/neonail/statistics/discount-codes-groups"
                //     }
                //   ]
                // },
                // {
                //   name: this.$t("nav.payment-methods"),
                //   path: "/de/neonail/statistics/payment-methods"
                // },
                // {
                //   name: this.$t("nav.delivery-methods"),
                //   path: "/de/neonail/statistics/delivery-methods"
                // },
                {
                  name: this.$t("nav.registrations"),
                  path: "/de/neonail/statistics/registrations"
                }
              ]
            }
          ]
        },
        {
          name: "NEONAIL ES",
          icon: '<i class="fa fa-shopping-cart"></i>',
          sub: [
            // {
            //   name: this.$t("nav.orders"),
            //   sub: [
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/es/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders-notes"),
            //       path: "/es/neonail/orders/notes"
            //     },
            //     {
            //       name: this.$t("nav.orders-not-sent"),
            //       path: "/es/neonail/orders/not-sent"
            //     }
            //   ]
            // },
            {
              name: this.$t("nav.products"),
              sub: [
                {
                  name: this.$t("nav.products"),
                  path: "/es/neonail/products"
                },
                {
                  name: this.$t("nav.product-list"),
                  path: "/es/neonail/products/product-list"
                },
                {
                  name: this.$t("nav.add-products-to-the-category"),
                  path: "/es/neonail/products/add-product-to-category"
                },
                {
                  name: this.$t("nav.set-configuration"),
                  path: "/es/neonail/products/add-product-to-category"
                },
                // {
                //   name: this.$t("nav.tags"),
                //   path: "/es/neonail/products/tags"
                // }
              ]
            },
            // {
            //   name: this.$t("nav.categories"),
            //   sub: [
            //     {
            //       name: this.$t("nav.categories"),
            //       path: "/es/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/es/neonail/add-products-to-the-category"
            //     }
            //   ]
            // },
            // {
            //   name: this.$t("nav.newsletter"),
            //   icon: '<i class="fa fa-th-large"></i>',
            //   path: "/es/neonail/newsletter"
            // },
            {
              name: this.$t("nav.awards-and-certificates"),
              icon: '<i class="fa fa-th-large"></i>',
              path: "/es/neonail/awards-and-certificates"
            },
            {
              name: this.$t("nav.subpages-lp-edu"),
              icon: '<i class="fa fa-file-code-o"></i>',
              path: "/es/neonail/subpages-lp-edu"
            },
            // {
            //   name: this.$t("nav.discount-codes"),
            //   path: "/es/neonail/discount-codes"
            // },
            // {
            //   name: this.$t("nav.customers"),
            //   path: "/es/neonail/customers"
            // },
            {
              name: this.$t("nav.faq"),
              path: "/es/neonail/faq"
            },
            {
              name: this.$t("nav.statistics"),
              sub: [
                // {
                //   name: this.$t("nav.sell"),
                //   sub: [
                //     {
                //       name: this.$t("nav.bestseller"),
                //       path: "/es/neonail/statistics/bestseller"
                //     },
                //     {
                //       name: this.$t("nav.free-deliveries"),
                //       path: "/es/neonail/statistics/free-deliveries"
                //     },
                //     {
                //       name: this.$t("nav.sales-products"),
                //       path: "/es/neonail/statistics/products"
                //     },
                //     {
                //       name: this.$t("nav.sales-categories"),
                //       path: "/es/neonail/statistics/categories"
                //     },
                //     {
                //       name: this.$t("nav.promotions"),
                //       path: "/es/neonail/statistics/promotions"
                //     },
                //     {
                //       name: this.$t("nav.collections"),
                //       path: "/es/neonail/statistics/collections"
                //     },
                //     {
                //       name: this.$t("nav.discount-codes"),
                //       path: "/es/neonail/statistics/discount-codes"
                //     },
                //     {
                //       name: this.$t("nav.discount-code-groups-statistics"),
                //       path: "/es/neonail/statistics/discount-codes-groups"
                //     }
                //   ]
                // },
                // {
                //   name: this.$t("nav.payment-methods"),
                //   path: "/es/neonail/statistics/payment-methods"
                // },
                // {
                //   name: this.$t("nav.delivery-methods"),
                //   path: "/es/neonail/statistics/delivery-methods"
                // },
                {
                  name: this.$t("nav.registrations"),
                  path: "/es/neonail/statistics/registrations"
                }
              ]
            },
            {
              name: this.$t("nav.presentations-gifts"),
              path: "/es/neonail/presentations-gifts"
            }
          ]
        },
        {
          name: "NEONAIL FR",
          icon: '<i class="fa fa-shopping-cart"></i>',
          sub: [
            // {
            //   name: this.$t("nav.orders"),
            //   sub: [
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/fr/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders-notes"),
            //       path: "/fr/neonail/orders/notes"
            //     },
            //     {
            //       name: this.$t("nav.orders-not-sent"),
            //       path: "/fr/neonail/orders/not-sent"
            //     }
            //   ]
            // },
            {
              name: this.$t("nav.products"),
              sub: [
                {
                  name: this.$t("nav.products"),
                  path: "/fr/neonail/products"
                },
                {
                  name: this.$t("nav.product-list"),
                  path: "/fr/neonail/products/product-list"
                },
                {
                  name: this.$t("nav.add-products-to-the-category"),
                  path: "/fr/neonail/products/add-product-to-category"
                },
                {
                  name: this.$t("nav.set-configuration"),
                  path: "/fr/neonail/products/add-product-to-category"
                },
                // {
                //   name: this.$t("nav.tags"),
                //   path: "/fr/neonail/products/tags"
                // }
              ]
            },
            // {
            //   name: this.$t("nav.categories"),
            //   sub: [
            //     {
            //       name: this.$t("nav.categories"),
            //       path: "/fr/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/fr/neonail/add-products-to-the-category"
            //     }
            //   ]
            // },
            // {
            //   name: this.$t("nav.newsletter"),
            //   icon: '<i class="fa fa-th-large"></i>',
            //   path: "/fr/neonail/newsletter"
            // },
            {
              name: this.$t("nav.awards-and-certificates"),
              icon: '<i class="fa fa-th-large"></i>',
              path: "/fr/neonail/awards-and-certificates"
            },
            {
              name: this.$t("nav.subpages-lp-edu"),
              icon: '<i class="fa fa-file-code-o"></i>',
              path: "/fr/neonail/subpages-lp-edu"
            },
            // {
            //   name: this.$t("nav.discount-codes"),
            //   path: "/fr/neonail/discount-codes"
            // },
            // {
            //   name: this.$t("nav.customers"),
            //   path: "/fr/neonail/customers"
            // },
            {
              name: this.$t("nav.faq"),
              path: "/fr/neonail/faq"
            },
            {
              name: this.$t("nav.statistics"),
              sub: [
                // {
                //   name: this.$t("nav.sell"),
                //   sub: [
                //     {
                //       name: this.$t("nav.bestseller"),
                //       path: "/fr/neonail/statistics/bestseller"
                //     },
                //     {
                //       name: this.$t("nav.free-deliveries"),
                //       path: "/fr/neonail/statistics/free-deliveries"
                //     },
                //     {
                //       name: this.$t("nav.sales-products"),
                //       path: "/fr/neonail/statistics/products"
                //     },
                //     {
                //       name: this.$t("nav.sales-categories"),
                //       path: "/fr/neonail/statistics/categories"
                //     },
                //     {
                //       name: this.$t("nav.promotions"),
                //       path: "/fr/neonail/statistics/promotions"
                //     },
                //     {
                //       name: this.$t("nav.collections"),
                //       path: "/fr/neonail/statistics/collections"
                //     },
                //     {
                //       name: this.$t("nav.discount-codes"),
                //       path: "/fr/neonail/statistics/discount-codes"
                //     },
                //     {
                //       name: this.$t("nav.discount-code-groups-statistics"),
                //       path: "/fr/neonail/statistics/discount-codes-groups"
                //     }
                //   ]
                // },
                // {
                //   name: this.$t("nav.payment-methods"),
                //   path: "/fr/neonail/statistics/payment-methods"
                // },
                // {
                //   name: this.$t("nav.delivery-methods"),
                //   path: "/fr/neonail/statistics/delivery-methods"
                // },
                {
                  name: this.$t("nav.registrations"),
                  path: "/fr/neonail/statistics/registrations"
                }
              ]
            }
          ]
        },
        {
          name: "NEONAIL IT",
          icon: '<i class="fa fa-shopping-cart"></i>',
          sub: [
            // {
            //   name: this.$t("nav.orders"),
            //   sub: [
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/it/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders-notes"),
            //       path: "/it/neonail/orders/notes"
            //     },
            //     {
            //       name: this.$t("nav.orders-not-sent"),
            //       path: "/it/neonail/orders/not-sent"
            //     }
            //   ]
            // },
            {
              name: this.$t("nav.products"),
              sub: [
                {
                  name: this.$t("nav.products"),
                  path: "/it/neonail/products"
                },
                {
                  name: this.$t("nav.product-list"),
                  path: "/it/neonail/products/product-list"
                },
                {
                  name: this.$t("nav.add-products-to-the-category"),
                  path: "/it/neonail/products/add-product-to-category"
                },
                {
                  name: this.$t("nav.set-configuration"),
                  path: "/it/neonail/products/add-product-to-category"
                }
                // {
                //   name: this.$t("nav.tags"),
                //   path: "/it/neonail/products/tags"
                // }
              ]
            },
            // {
            //   name: this.$t("nav.categories"),
            //   sub: [
            //     {
            //       name: this.$t("nav.categories"),
            //       path: "/it/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/it/neonail/add-products-to-the-category"
            //     }
            //   ]
            // },
            // {
            //   name: this.$t("nav.newsletter"),
            //   icon: '<i class="fa fa-th-large"></i>',
            //   path: "/it/neonail/newsletter"
            // },
            {
              name: this.$t("nav.awards-and-certificates"),
              icon: '<i class="fa fa-th-large"></i>',
              path: "/it/neonail/awards-and-certificates"
            },
            {
              name: this.$t("nav.subpages-lp-edu"),
              icon: '<i class="fa fa-file-code-o"></i>',
              path: "/it/neonail/subpages-lp-edu"
            },
            // {
            //   name: this.$t("nav.discount-codes"),
            //   path: "/it/neonail/discount-codes"
            // },
            // {
            //   name: this.$t("nav.customers"),
            //   path: "/it/neonail/customers"
            // },
            {
              name: this.$t("nav.faq"),
              path: "/it/neonail/faq"
            },
            {
              name: this.$t("nav.statistics"),
              sub: [
                // {
                //   name: this.$t("nav.sell"),
                //   sub: [
                //     {
                //       name: this.$t("nav.bestseller"),
                //       path: "/it/neonail/statistics/bestseller"
                //     },
                //     {
                //       name: this.$t("nav.free-deliveries"),
                //       path: "/it/neonail/statistics/free-deliveries"
                //     },
                //     {
                //       name: this.$t("nav.sales-products"),
                //       path: "/it/neonail/statistics/products"
                //     },
                //     {
                //       name: this.$t("nav.sales-categories"),
                //       path: "/it/neonail/statistics/categories"
                //     },
                //     {
                //       name: this.$t("nav.promotions"),
                //       path: "/it/neonail/statistics/promotions"
                //     },
                //     {
                //       name: this.$t("nav.collections"),
                //       path: "/it/neonail/statistics/collections"
                //     },
                //     {
                //       name: this.$t("nav.discount-codes"),
                //       path: "/it/neonail/statistics/discount-codes"
                //     },
                //     {
                //       name: this.$t("nav.discount-code-groups-statistics"),
                //       path: "/it/neonail/statistics/discount-codes-groups"
                //     }
                //   ]
                // },
                // {
                //   name: this.$t("nav.payment-methods"),
                //   path: "/it/neonail/statistics/payment-methods"
                // },
                // {
                //   name: this.$t("nav.delivery-methods"),
                //   path: "/it/neonail/statistics/delivery-methods"
                // },
                {
                  name: this.$t("nav.registrations"),
                  path: "/it/neonail/statistics/registrations"
                }
              ]
            }
          ]
        },
        {
          name: "NEONAIL NL",
          icon: '<i class="fa fa-shopping-cart"></i>',
          sub: [
            // {
            //   name: this.$t("nav.orders"),
            //   sub: [
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/nl/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders-notes"),
            //       path: "/nl/neonail/orders/notes"
            //     },
            //     {
            //       name: this.$t("nav.orders-not-sent"),
            //       path: "/nl/neonail/orders/not-sent"
            //     }
            //   ]
            // },
            {
              name: this.$t("nav.products"),
              sub: [
                {
                  name: this.$t("nav.products"),
                  path: "/nl/neonail/products"
                },
                {
                  name: this.$t("nav.product-list"),
                  path: "/nl/neonail/products/product-list"
                },
                {
                  name: this.$t("nav.add-products-to-the-category"),
                  path: "/nl/neonail/products/add-product-to-category"
                },
                {
                  name: this.$t("nav.set-configuration"),
                  path: "/nl/neonail/products/add-product-to-category"
                }
                // {
                //   name: this.$t("nav.tags"),
                //   path: "/nl/neonail/products/tags"
                // }
              ]
            },
            // {
            //   name: this.$t("nav.categories"),
            //   sub: [
            //     {
            //       name: this.$t("nav.categories"),
            //       path: "/nl/neonail/orders"
            //     },
            //     {
            //       name: this.$t("nav.orders"),
            //       path: "/nl/neonail/add-products-to-the-category"
            //     }
            //   ]
            // },
            // {
            //   name: this.$t("nav.newsletter"),
            //   icon: '<i class="fa fa-th-large"></i>',
            //   path: "/nl/neonail/newsletter"
            // },
            {
              name: this.$t("nav.awards-and-certificates"),
              icon: '<i class="fa fa-th-large"></i>',
              path: "/nl/neonail/awards-and-certificates"
            },
            {
              name: this.$t("nav.subpages-lp-edu"),
              icon: '<i class="fa fa-file-code-o"></i>',
              path: "/nl/neonail/subpages-lp-edu"
            },
            // {
            //   name: this.$t("nav.discount-codes"),
            //   path: "/nl/neonail/discount-codes"
            // },
            // {
            //   name: this.$t("nav.customers"),
            //   path: "/nl/neonail/customers"
            // },
            {
              name: this.$t("nav.faq"),
              path: "/nl/neonail/faq"
            },
            {
              name: this.$t("nav.statistics"),
              sub: [
                // {
                //   name: this.$t("nav.sell"),
                //   sub: [
                //     {
                //       name: this.$t("nav.bestseller"),
                //       path: "/nl/neonail/statistics/bestseller"
                //     },
                //     {
                //       name: this.$t("nav.free-deliveries"),
                //       path: "/nl/neonail/statistics/free-deliveries"
                //     },
                //     {
                //       name: this.$t("nav.sales-products"),
                //       path: "/nl/neonail/statistics/products"
                //     },
                //     {
                //       name: this.$t("nav.sales-categories"),
                //       path: "/nl/neonail/statistics/categories"
                //     },
                //     {
                //       name: this.$t("nav.promotions"),
                //       path: "/nl/neonail/statistics/promotions"
                //     },
                //     {
                //       name: this.$t("nav.collections"),
                //       path: "/nl/neonail/statistics/collections"
                //     },
                //     {
                //       name: this.$t("nav.discount-codes"),
                //       path: "/nl/neonail/statistics/discount-codes"
                //     },
                //     {
                //       name: this.$t("nav.discount-code-groups-statistics"),
                //       path: "/nl/neonail/statistics/discount-codes-groups"
                //     }
                //   ]
                // },
                // {
                //   name: this.$t("nav.payment-methods"),
                //   path: "/nl/neonail/statistics/payment-methods"
                // },
                // {
                //   name: this.$t("nav.delivery-methods"),
                //   path: "/nl/neonail/statistics/delivery-methods"
                // },
                {
                  name: this.$t("nav.registrations"),
                  path: "/nl/neonail/statistics/registrations"
                }
              ]
            }
          ]
        },
        {
          name: "NEONAIL EXPERT PL",
          icon: '<i class="fa fa-th-large"></i>',
          sub: [
            {
              name: this.$t("nav.newsletter"),
              icon: '<i class="fa fa-th-large"></i>',
              path: "/pl/neonail-expert-pl/newsletter"
            }
          ]
        },
        {
          name: this.$t("nav.payment-control"),
          icon: '<i class="fa fa-th-large"></i>',
          path: "/pl/kontrola-platnosci"
        },
        {
          name: this.$t("nav.short-redirect"),
          icon: '<i class="fa fa-ioxhost"></i>',
          path: "/pl/neonail/short-redirects"
        },
        {
          name: this.$t("nav.animation-islands"),
          icon: '<i class="fa fa-file-movie-o"></i>',
          path: "/animation-islands"
        },
        {
          name: this.$t("nav.qr-code"),
          icon: '<i class="fa fa-qrcode"></i>',
          sub: [
            {
              name: this.$t("nav.generate"),
              path: "/pl/neonail/qr-code/generate"
            },
            {
              name: this.$t("nav.upload-files"),
              path: "/pl/neonail/qr-code/upload-files"
            }
          ]
        },
        {
          name: this.$t("nav.courses"),
          icon: '<i class="fa fa-th-large"></i>',
          sub: [
            {
              name: this.$t("nav.applications-list"),
              path: "/pl/neonail/courses/applications-list"
            },
            {
              name: this.$t("nav.training-cities-list"),
              path: "/pl/neonail/courses/cities"
            },
            {
              name: this.$t("nav.training-dates-list"),
              path: "/pl/neonail/courses/training-dates"
            },
            {
              name: this.$t("nav.training-categories-list"),
              path: "/pl/neonail/courses/training-category"
            },
            {
              name: this.$t("nav.training-trainings-list"),
              path: "/pl/neonail/courses/training-trainings"
            },
            // {
            //   name: this.$t("nav.training-dates-list"),
            //   path: "/pl/neonail/courses/seo"
            // },
            {
              name: this.$t("nav.training-stylists-list"),
              path: "/pl/neonail/courses/stylists"
            },
            {
              name: this.$t("nav.training-regulations"),
              path: "/pl/neonail/courses/regulations"
            }
          ]
        }
      ]
    };
  },
  computed: {
    user() {
      let user = null;
      if (this.msal.isAuthenticated) {
        // Note that the dollar sign ($) is missing from this.msal
        user = {
          ...this.msal.user,
          profile: {}
        };
        if (this.msal.graph && this.msal.graph.profile) {
          user.profile = this.msal.graph.profile;
        }
      }
      return user;
    }
  },
  created() {
    this.initNav();
    // console.log(NavbarService.list());
  },
  methods: {
    initNav() {
      $( document ).on( 'show.bs.collapse', function( e ) { // eslint-disable-line
          $( e.target ).parent().addClass( 'active' ); // eslint-disable-line
      });

      $( document ).on( 'hide.bs.collapse', function( e ) { // eslint-disable-line
          $( e.target ).parent().removeClass( 'active' ); // eslint-disable-line
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.nav > li > a {
  padding: 14px 20px 14px 10px;
}

.nav.nav-second-level > li.active {
  border: none;
  background: #26333f;
}
ul.nav-second-level {
  background: #293846;
}
.nav-second-level li a {
  padding: 7px 10px 7px 10px;
  padding-left: 26px;
}

.nav-third-level li a {
  padding-left: 36px;
}
.nav.nav-third-level > li.active {
  border: none;
  background: #212b35;
}
ul.nav-third-level {
  background: #26333f;
}

.nav.nav-third-level > li.active {
  border: none;
  background: #212b35;
}
.nav-fourth-level li a {
  padding-left: 46px;
}
ul.nav-fourth-level {
  background: #212b35;
}
a:not([href]):not([tabindex]) {
  color: #a7b1c2;
  text-decoration: none;
}
</style>
