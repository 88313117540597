import { Validator } from "vee-validate";

const CustomValidations = {
  init() {
    Validator.extend("duplicate_plum", {
      getMessage: () => "field_duplicate_plum",
      validate: value => {
        return this.duplicatedPlum(value);
      }
    });
    const dictionary = {
      en: {
        messages: {
          required: () => "field_required"
        }
      }
    };
    Validator.localize(dictionary);
  },
  convertString(text) {
    return text
      .replace(/['".]+/g, "")
      .replace(/\s+/g, ",")
      .replace(/,+/g, ",")
      .trim();
  },
  convertStringToArray(text) {
    const sparseArray = text.split(",");
    const cleanArray = sparseArray.filter(Boolean);
    return cleanArray;
  },
  checkDuplicate(array) {
    return array.some((val, i) => array.indexOf(val) !== i);
  },
  duplicatedPlum(value) {
    let convertText = this.convertString(value);
    let plumsArray = this.convertStringToArray(convertText);
    let duplicateCheckArray = this.checkDuplicate(plumsArray);
    return duplicateCheckArray == true ? false : true;
  }
};

export default CustomValidations;
